<script>
import Mapbox from 'mapbox-gl';
export default {
  name: "LocationPicker",
  emits: ['changeMarker'],
  props: {
    modelValue: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    modelValue: {
      handler (newValue, oldValue) {
        console.log('watch modelValue')
        console.log(newValue)
        console.log(newValue)
        if (oldValue && oldValue.length && newValue && newValue.length && newValue[0] !== oldValue[0] && newValue[1] !== oldValue[1]) {
          this.updateMarker(newValue)
        } else if (newValue && newValue.length && !oldValue) {
          this.updateMarker(newValue)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      mapReady: false,
      mapbox: null,
      marker: null
    }
  },
  methods: {
    updateMarker (lngLat) {
      console.log('update marker')
      console.log(lngLat)
      if (this.marker) {
        this.marker.remove()
      }
      this.marker = new Mapbox.Marker({
        color: "#fc2249",
        draggable: true
      }).setLngLat(lngLat)
          .addTo(this.mapbox);
      this.mapbox.setCenter(lngLat);
      this.marker.on('dragend', () => {
        const lngLat = this.marker.getLngLat();
        // let lngLat = this.marker.getLngLat();
        this.$emit('update:modelValue', [lngLat.lng, lngLat.lat]);
        this.$emit('changeMarker', [lngLat.lng, lngLat.lat])
      });
    }
  },
  mounted () {
    let mapConfig = {
      container: this.$refs.mapboxGl,
      style: 'mapbox://styles/mapbox/streets-v9', // stylesheet location
      // style: 'mapbox://styles/jonathan-lightyshare/ckia4xpw3764n1apc23o4t3hy', // stylesheet location
      zoom: 11,
      minZoom: 6,
      maxZoom: 200,
      scrollZoom: true
    }
    if (this.modelValue.length > 0) {
      mapConfig.center = this.modelValue
    } else {
      mapConfig.center = [2.3522219, 48.856614]
    }
    this.mapbox = new Mapbox.Map(mapConfig);
    this.mapbox.on('load', () => {
      this.mapReady = true;  // Définir la carte comme prête
      if (this.modelValue.length) {
        this.updateMarker(this.modelValue);  // Mettre à jour le marqueur une fois la carte prête
      }
    });
    this.mapbox.on('style.load', () => {
      // Assure-toi que la couche existe avant de l'ajouter ou de l'utiliser
      if (this.mapbox.getLayer('landcover-outdoors') && this.mapbox.getSource('composite')) {
        // Ton code pour utiliser la couche ici
      } else {
        console.warn('La couche ou la source spécifiée n\'existe pas');
      }
    });
    this.mapbox.on('click', (e) => {
      // Les coordonnées du point cliqué sont dans e.lngLat
      let longitude = e.lngLat.lng;
      let latitude = e.lngLat.lat;
      this.updateMarker([longitude, latitude])
      this.$emit('update:modelValue', [longitude, latitude]);
      this.$emit('changeMarker', [longitude, latitude])

      // Vous pouvez utiliser ces coordonnées comme vous le souhaitez
    });
    this.mapbox.on('mouseover', () => {
      this.mapbox.getCanvas().style.cursor = 'pointer';
    });
  }
}
</script>

<template>
  <div id="map" ref="mapboxGl" v-bind="$attrs"></div>
</template>

<style scoped>
#map {
  cursor: pointer;
}
</style>