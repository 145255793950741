import { Model } from '@vuex-orm/core'
import Society from "./Society.js";
import ImageProfil from "./ImageProfil.js";

export default class User extends Model {
  static entity = 'user'
  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'user',
    // actions: {
    //   fetch: {
    //     method: 'get',
    //     url: 'billings'
    //   }
    // }
  }

  static fields () {
    return {
      id: this.attr(null),
      statutUser: this.attr(null),
      fullName: this.attr(''),
      prenom: this.attr(''),
      nom: this.attr(''),
      email: this.attr(''),
      dateNaissanceString: this.attr(null),
      adresseForFacturation: this.attr(null),
      currentAdresse: this.attr(null),
      defaultAdresse: this.attr(null),
      societeAdresse: this.attr(null),
      adresseSiege: this.attr(null),
      adresseFactu: this.attr(null),
      adresse: this.attr(null),
      defaultFacturationAdresse: this.attr(null),
      telephone: this.attr({
        telephone: '',
        pays: null
      }),
      nationality: this.attr('FR'),
      countryOfResidence: this.attr('FR'),
      kycDocuments: this.attr([]),
      infoSociete: this.hasOne(Society, 'user_id'),
      ImageProfil: this.hasOne(ImageProfil, 'user_id'),
      codes_promo: this.attr([]),
      kycDocumentationId: this.attr(null),
      kycDocumentationKbis: this.attr(null),
      lastLoginUnix: this.attr(null),
			createdAtUnix: this.attr(null),
      ubbleAttemptsLeft: this.attr(null),
      lastUbbleAttempt: this.attr(null),
      kycDocumentationSirene: this.attr(null),
      kycDocumentationStatut: this.attr(null),
      countTenantAsk: this.attr(null),
      countOwnerAsk: this.attr(null),
      kycDocumentationUbo: this.attr(null),
      idKycDocuments: this.attr(null),
      kbisKycDocuments: this.attr(null),
      sirenKycDocuments: this.attr(null),
      statusKycDocuments: this.attr(null),
      uboKycDocuments: this.attr(null),
      countCancellation: this.attr(null),
			boutiqueActive: this.attr(null),
			isExpert: this.attr(null),
			isDeleted: this.attr(null),
      lightyShareCertifed: this.attr(null),
			adresseCertifed: this.attr(null),
			idCertifed: this.attr(null),
      flag: this.attr(null),
			telephoneCertifed: this.attr(null),
    }
  }
}
