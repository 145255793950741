import axios from "axios";
export const replaceSubObjectWithId = (obj) => {
  obj = {
    ...obj
  }
  // obj = flattenObject(content)
  for (const key in obj) {
    const value = obj[key]
    if (typeof value === 'object' && value !== null && !Array.isArray(value) && value.id) {
      obj[key] = value.id
    } else if (Array.isArray(value)) {
      obj[key] = value.map((v) => {
        if (typeof v.id === 'number') {
          return v.id
        } else {
          return v
        }
      })
    }
    // console.log(obj)
  }
  return obj
}
export const flattenObject = (obj, root = true) => {
  const flattened = {}
  if (!typeof value === 'object' && !Array.isArray(value)) {
    return value
  }
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (!root) {
      key = `[${key}]`
    }
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const flattenSubObject = flattenObject(value, false)
      Object.keys(flattenSubObject).forEach((fkey) => {
        flattened[`${key}${fkey}`] = flattenSubObject[fkey]
      })
    } else if (Array.isArray(value)) {
      for (let i in value) {
        const arrayValues = flattenObject(value[i], false)
        if (!typeof arrayValues === 'object' && !Array.isArray(arrayValues)) {
          flattened[`${key}[${i}]`] = arrayValues
        } else {
          // console.log(`${}  ${i}`)
          Object.keys(arrayValues).forEach((akey) => {
            flattened[`${key}[${i}]${akey}`] = arrayValues[akey]
          })
        }
      }
    } else {
      flattened[key] = value
    }
  })

  return flattened
}

export async function dashboard () {
  let config = {
    headers: {'content-type': 'application/json'}
  }
  const response = await axios.get('', config)
  return response
  // console.log(response)
}

export async function loadPageContent (url = '') {
  let config = {
    headers: {'content-type': 'application/json'}
  }
  const response = await axios.get(url, config)
  return response
  // console.log(response)
}

export function notContainStringInArray (search, array = []) {
  return !array.reduce((isContain, item) => {
    return isContain || search.includes(item)
  }, false)
}

export function transformDataToFormContent(content, fields = [], ignoreFields = [], allowNull = false) {
  let datas = new FormData();
  const formContent = flattenObject(content)
  for (const key in formContent) {
    if (!fields.length || (fields.length && fields.includes(key))) {
      if (notContainStringInArray(key, ignoreFields) && (!allowNull && formContent[key] !== null)|| allowNull) {
        datas.append(key, formContent[key])
      }
    }
  }
  return datas
}

export async function sendFormContent (content) {
  if (content.usePersonnalAdress) {
    content.addresseGeoloc = null
  }
  let datas = new FormData();
  console.log('content')
  console.log(content)
  const formContent = flattenObject(content)
  console.log('formContent')
  console.log(formContent)
  for (const key in formContent) {
    datas.append(key, formContent[key])
  }
  console.log(datas.values())
  let config = {
    headers: {'content-type': 'application/json'}
  }
  const response = await axios.post('', datas, config)
  console.log(response)
  return response
}