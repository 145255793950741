import {Model} from "@vuex-orm/core";

export default class Society extends Model {
  static entity = 'society'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      capital: this.attr(0),
      raisonSociale: this.attr(''),
      siret: this.attr(''),
      hasVat: this.attr(''),
      validVAT: this.attr(null),
      vatCountry: this.attr(''),
      vatNumber: this.attr(''),
      numeroRcs: this.attr(''),
      formeJuridique: this.attr(''),
      nomLegal: this.attr(null),
      prenomLegal: this.attr(null),
      birthdateLegalString: this.attr(null),
      legalCountryOfResidence: this.attr('FR'),
      legalNationality: this.attr('FR'),
    }
  }
}