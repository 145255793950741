import { Model } from '@vuex-orm/core'

export default class ImageProfil extends Model {
  static entity = 'imageProfil'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(''),
      imageName: this.attr('')
    }
  }
}